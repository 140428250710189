import { Box } from "@mui/material";
import React from "react";

export const Support: React.FC = () => {
  return (
    <Box>
      <h1>Najczęściej zadawane pytania</h1>
      <h2>Jak dodać dokument do mojej Teczki Pacjenta?</h2>
      <ol>
        <p>
          Do swojej teczki możesz dodawać różnorodne dokumenty medyczne, badania laboratoryjne oraz recepty. Zostaną one
          uporządkowane chronologicznie, a dodatkowo badania laboratoryjne zostaną odczytane przez nasz algorytm by ich
          interpretacja była łatwiejsza dla Ciebie i Twojego lekarza.
        </p>
        <li>W aplikacji przejdź do sekcji "Dodaj Badanie" i kliknij w przycisk dodawania dokumentu</li>
        <li>
          Wybierz źródło dokumentu:
          <ul>
            <li>Aparat (możesz zrobić zdjęcie dokumentu papierowego),</li>
            <li>Galeria (możesz dodać plik jpg lub png) lub</li>
            <li>Pliki (możesz dodać plik pdf).</li>
          </ul>
        </li>
        <li>
          Na formularzu dodawania dokumentu uzupełnij informacje:
          <ul>
            <li>Kategoria dokumentu - pomoże w katalogowaniu dokumentów</li>
            <li>Data wystawienia dokumentu - pomoże Ci śledzić Twoją historię medyczną</li>
            <li>Możesz dodać swoją nazwę, która ułatwi Ci późniejsze odnalezienie dokumentu</li>
            <li>W tym miejscu masz też możliwość zmiany dokumentu.</li>
          </ul>
        </li>
        <li>Kliknij "Prześlij". Twój dokument został dodany.</li>
      </ol>
      <h2>Jak zweryfikować badanie laboratoryjne?</h2>
      <ol>
        <p>
          Jeśli wybrałeś kategorię "Badania laboratoryjne" nasz algorytm odczytał Twoje wyniki badań i przetworzył je ze
          zdjęcia na edytowalny tekst. Weryfikacja jest krokiem, który jest niezbędny by potwierdzić zgodność tego
          odczytu z oryginalnym badaniem. Dzięki temu lekarz będzie mógł postawić lepszą diagnozę.
        </p>
        <li>
          Przejrzyj wyniki zeskanowanego badania i porównaj z oryginałem (badaniem papierowym, zdjęciem lub pdfem).
        </li>
        <li>Jeśli występują jakieś rozbieżności możesz edytować dany wpis.</li>
        <li>
          Jeśli wszystko się zgadza lub zakończyłeś edycję, kliknij przycisk "Zweryfikuj" na dole ekranu. Badanie jest
          zweryfikowane.
        </li>
      </ol>
      <h2>Jak udostępnić wyniki badań lekarzowi?</h2>
      <ol>
        <p>
          Nie musisz już nosić całej sterty dokumentów na wizyty lekarskie. Wystarczy, że udostępnisz swoje wyniki badań
          poprzez aplikację.
        </p>
        <li>Poproś lekarza lub inną osobę z personelu medycznego by weszła na stronę teczkapacjenta.pl</li>
        <li>W swojej aplikacji przejdź do zakładki "Dokumenty" i kliknij przycisk "Udostępnij wyniki badań"</li>
        <li>Podyktuj lekarzowi kod dostępny na ekranie.</li>
        <li>
          Gdy lekarz wpisze kod w przeglądarce, będziesz mógł zaakceptować jego prośbę o przeglądanie Twoich dokumentów
          medycznych.
        </li>
        <li>Lekarz będzie miał dostęp do Twoich dokumentów przez godzinę.</li>
      </ol>
      <h2>Masz inne pytania?</h2>
      <p>
        Napisz do nas <a href='mailto:kontakt@healthfolder.pl'>kontakt@healthfolder.pl</a>
      </p>
    </Box>
  );
};
