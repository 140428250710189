import { faCircleArrowDown, faCircleArrowUp, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export const NormReferenceLegend: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack direction='row' spacing={{ md: 3, sm: 2, xs: 1 }} mt={3} flexWrap='wrap'>
      <Stack direction='row' alignItems='baseline' spacing={1}>
        <FontAwesomeIcon icon={faCircle} color='#90E020' size='xs' />
        <Typography variant='body2'>{t('normReferenceLegend.resultsNormal')}</Typography>
      </Stack>
      <Stack direction='row' alignItems='baseline' spacing={1}>
        <FontAwesomeIcon icon={faCircleArrowUp} color='#FFBD12' size='xs' />
        <FontAwesomeIcon icon={faCircleArrowDown} color='#FFBD12' size='xs' />
        <Typography variant='body2'>{t('normReferenceLegend.standardExceeded')}</Typography>
      </Stack>
      <Stack direction='row' alignItems='baseline' spacing={1}>
        <FontAwesomeIcon icon={faCircle} color='#597FFF' size='xs' />
        <Typography variant='body2'>{t('normReferenceLegend.noReferenceRange')}</Typography>
      </Stack>
    </Stack>
  );
};
