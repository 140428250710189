import { faXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import React from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SearchBar: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.getAll("terms");
  const cat = searchParams.getAll("cat");

  const search = async (terms: string[]) => {
    let params = createSearchParams({
      terms,
      cat,
    });
    setSearchParams(params);
  };

  return (
    <Box>
      <Autocomplete
        multiple
        id='search'
        options={[]}
        defaultValue={[]}
        freeSolo
        clearIcon={<FontAwesomeIcon icon={faXmark} color='#FF3A3A' width='16' height='16' />}
        value={q}
        onChange={(_evt, newValue) => {
          search(newValue as string[]);
        }}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant='filled'
              deleteIcon={<FontAwesomeIcon icon={faXmark} color='#A0AAB1' width='16' height='16' />}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => {
          return (
            /* @ts-ignore */
            <TextField
              {...params}
              variant='outlined'
              placeholder={t('searchBar.placeholder')}
              sx={{ color: "#526D75" }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!params.InputProps.endAdornment ? (
                      <Box mr={1}>
                        <FontAwesomeIcon icon={faSearch} color=' #1F52FF' />
                      </Box>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    </Box>
  );
};
