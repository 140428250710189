import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { StyledDocumentListItem } from "./StyledDocumentListItem";
import { DocumentCategory, DocumentStatus } from "../types/document";
import { LabDocumentItem } from "./LabDocumentItem";
import { OtherDocumentItem } from "./OtherDocumentItem";
import { Measurement } from "../types/measurement";
import { ExaminationType } from "../utils";
import {useTranslation} from "react-i18next";

type DocumentListItem = {
  documentId: string;
  samplingDate: string;
  filePath: string;
  status: DocumentStatus;
  category: DocumentCategory;
  title: string;
  examinations: Array<{ type: ExaminationType; measurements: Measurement[] }>;
};

export interface DocumentListProps {
  documents: Array<DocumentListItem>;
}

export const DocumentList: React.FC<DocumentListProps> = ({ documents }) => {
  const { t } = useTranslation();
  return documents.length === 0 ? (
    <Box>
      <Typography>
        <FontAwesomeIcon icon={faCircleInfo} color='#597FFF' /> {t('documentList.noDocumentsUploaded')}
      </Typography>
    </Box>
  ) : (
    <TableContainer>
      <Table sx={{ borderSpacing: "0 16px", borderCollapse: "separate" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Grid container columnSpacing={{ xs: 0, sm: 2, md: 2 }}>
                <Grid item xxs={12} xs={5} sm={3.5} md={2.5}>
                  <Box sx={{ paddingLeft: 6 }}>{t('documentList.date')}</Box>
                </Grid>
                <Grid item xxs={12} xs sm md>
                  {t('documentList.document')}
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <StyledDocumentListItem key={document.documentId}>
              {document.category === DocumentCategory.Lab ? (
                <LabDocumentItem {...document} />
              ) : (
                <OtherDocumentItem {...document} />
              )}
            </StyledDocumentListItem>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
