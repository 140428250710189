import React from "react";
import { NormReference } from "../types/normReference";
import { faCircle, faCircleArrowDown, faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps } from "@mui/system";

export interface MeasurementNormProps {
  norm: NormReference | string;
}

export const MeasurementNorm: React.FC<MeasurementNormProps & BoxProps> = ({ norm, ...props }) => {
  const render = () => {
    switch (norm) {
      case NormReference.Above:
        return <FontAwesomeIcon icon={faCircleArrowUp} color='#FFBD12' />;
      case NormReference.Below:
        return <FontAwesomeIcon icon={faCircleArrowDown} color='#FFBD12' />;
      case NormReference.Normal:
        return <FontAwesomeIcon icon={faCircle} color='#90E020' />;
      case NormReference.Unknown:
        return <FontAwesomeIcon icon={faCircle} color='#597FFF' />;
      default:
        return <FontAwesomeIcon icon={faCircle} color='#597FFF' />;
    }
  };
  return <Box {...props} component="span">{render()}</Box>;
};
