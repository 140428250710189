import { apiURL, createDefaultHeaders } from ".";
import { buildUrlWithSearchParams } from "../utils/api";

export const getDocumentList = async ({ token, query, cat }: { token: string; query: string[]; cat: string[] }) => {
  const url = buildUrlWithSearchParams({ url: `${apiURL}/v2/users/share/documents`, values: query, categories: cat });
  const response = await fetch(url, { headers: createDefaultHeaders(token) });
  if (!response.ok) {
    throw new Error("Request failed");
  }
  const jsonData = await response.json();
  return jsonData;
};

export const getDocumentDetails = async ({ documentId, token }: { documentId: string; token: string }) => {
  const url = `${apiURL}/users/share/documents/${documentId}`;

  const response = await fetch(url, { headers: createDefaultHeaders(token) });
  if (!response.ok) {
    throw new Error("Request failed");
  }
  const jsonData = await response.json();
  return jsonData;
};

export const getDocumentPreviewUrl = async ({ documentId, token }: { documentId: string; token: string }) => {
  const url = `${apiURL}/users/share/documents/${documentId}/preview`;
  let objectURL = "";

  return fetch(url, { headers: createDefaultHeaders(token) })
    .then((response) => response.blob())
    .then((myBlob) => {
      objectURL = URL.createObjectURL(myBlob);
      return objectURL;
    });
};
