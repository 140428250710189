import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Container, Grid, Stack, Toolbar, Typography, Snackbar, SnackbarOrigin } from "@mui/material";
import { MainNavigation } from "./MainNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { ResponsiveIconButton } from "../components/ResponsiveIconButton";
import logoPl from "../assets/images/teczka-pacjenta-logo.svg";
import logoEn from "../assets/images/logo-en.svg";
import { ShareResultsNav } from "./ShareResultsNav";
import { SearchBar } from "../components/SearchBar";

import { useTranslation } from "react-i18next";
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface State extends SnackbarOrigin {
  open: boolean;
}

export const MainBar = () => {
  const { t, i18n } = useTranslation();
  const logo = i18n.language === "pl" ? logoPl : logoEn;
  const navigate = useNavigate();
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const isLogged = token !== null;

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    return navigate("/");
  };

  const [state, setState] = React.useState<State>({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
  };

  const snackBarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const snackBarMessage = (
    <React.Fragment>
      <p style={{fontSize: "20px"}} dangerouslySetInnerHTML={{__html: t("snackbar.welcome")}}>
      </p>
    </React.Fragment>
  );

  return (
    <div>
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={30000}
        onClose={handleClose}
        message={snackBarMessage}
        key={vertical + horizontal}
        action={snackBarAction}
      />
    <AppBar position='sticky' color='default' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      {isLogged && (
        <Box
          sx={{
            backgroundColor: "#C2D0FF",
            paddingX: 3,
            paddingY: 1,
            minHeight: "66px",
          }}
        >
          <Container maxWidth={"xl"} disableGutters>
            <Grid container direction='row' alignItems='center'>
              <Grid xxs={12} md={2} item>
                <img src={logo} alt='Logo Teczka Pacjenta' width='100' />
              </Grid>
              <Grid xxs={12} md={8} item>
                {!location.pathname.endsWith("results") ? null : <SearchBar />}
              </Grid>
              <Grid xxs={12} md={2} item display='flex' justifyContent='flex-end'>
                <ResponsiveIconButton
                  text={t("logoutButton.text")}
                  icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                  onClick={logout}
                  variant='outlined'
                  size='small'
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <Container maxWidth={"xl"} disableGutters>
        <Toolbar disableGutters={!isLogged}>
          {isLogged ? (
            <>
              <Stack flexGrow={1}>
                <Box pt={2} mb={2}>
                  <Typography variant='subtitle1' color='text.secondary'>
                    {t("mainBar.text")}
                  </Typography>
                  <Typography variant='h2' color='text.primary'>
                    {firstName} {lastName}
                  </Typography>
                </Box>

                <ShareResultsNav />
              </Stack>
            </>
          ) : (
            <MainNavigation />
          )}
        </Toolbar>
      </Container>
    </AppBar>

    </div>
  );
};
