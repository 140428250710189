import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

export const DEFAULT_LOCALE = "pl";
const supportedLngs = ["pl", "en"];

const domainLocaleMap: Record<string, string> = {
  localhost: "pl",
  "teczkapacjenta.pl": "pl",
  "app.dev.teczkapacjenta.pl": "pl",
  "myhealthfolder.online": "en",
};

const domainDetector = () => {
  let locale = DEFAULT_LOCALE;
  if (typeof window !== "undefined") {
    locale = domainLocaleMap[window.location.hostname];
  }
  return locale;
};

const handleLangByHostname = () => {
  const locale = domainDetector();

  document.documentElement.setAttribute("lang", locale);
  document.title = locale === "pl" ? "Teczka Pacjenta" : "Health Folder";

  return locale;
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: handleLangByHostname(),
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
