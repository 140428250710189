import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, Divider, Grid, Container } from "@mui/material";
import logo from "../assets/images/teczka-pacjenta-logo.svg";
import logoEn from "../assets/images/logo-en.svg";
import { useNavigate } from "react-router-dom";
import { PinCode } from "../components/PinCode";
import { CodeExpiredError, CodeNotFoundError, authorizeWithShareCode, getShareCodeStatus } from "../api/auth";
import { ParsedTokenData, parseJwtToken } from "../utils/api";
import { CountDown } from "../components/CountDown";
import { LoginPatient } from "../components/LoginPatient";
import { DisplayShareCode } from "../components/DisplayShareCode";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInSeconds } from "date-fns";
import { useTranslation } from "react-i18next";

export interface LoginProps {
  isPatient?: boolean;
}

enum LoginFormState {
  FirstAttempt = "FIRST_ATTEMPT",
  CodeExpired = "CODE_EXPIRED",
  WaitingVerification = "WAITING_VERIFICATION",
}

export const Login: React.FC<LoginProps> = ({ isPatient = false }) => {
  const { t, i18n } = useTranslation();
  const logoImg = i18n.language === "pl" ? logo : logoEn;
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | undefined>();
  const [code, setCode] = useState("");
  const [codeId, setCodeId] = useState("");
  const [loginFormState, setLoginFormState] = useState<LoginFormState>(LoginFormState.FirstAttempt);
  const [expirationTimeInSeconds, setExpirationTimeInSeconds] = useState(110);
  const errorMessages = {
    codeExpired: t("login.form.error.codeExpired"),
    codeNotFound: t("login.form.error.codeNotFound"),
  };

  const login = useCallback(
    async (code: string) => {
      setCode(code);
      authorizeWithShareCode(code)
        .then((response) => {
          if (response.codeId) {
            setLoginFormState(LoginFormState.WaitingVerification);
            setCodeId(response.codeId);
            if (response.expiresAt) {
              setExpirationTimeInSeconds(differenceInSeconds(new Date(response.expiresAt), new Date()) || 110);
            }
            return;
          }
          const { token } = response;
          const patientData: ParsedTokenData["access"] = parseJwtToken(token).access;
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("firstName", patientData.firstName);
          sessionStorage.setItem("lastName", patientData.lastName);
          navigate("/results");
        })
        .catch((error) => {
          if (error instanceof CodeExpiredError) {
            setLoginError(errorMessages.codeExpired);
          }
          if (error instanceof CodeNotFoundError) {
            setLoginError(errorMessages.codeNotFound);
          }
        });
    },
    [navigate, errorMessages.codeExpired, errorMessages.codeNotFound]
  );

  useEffect(() => {
    const checkCodeStatus = () => {
      if (codeId) {
        setLoginFormState(LoginFormState.WaitingVerification);
        getShareCodeStatus(codeId)
          .then((response) => response.json())
          .then((data) => {
            const { status } = data;

            if (status === "APPROVED") {
              clearInterval(checkingStatusInterval);
              login(code);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    };

    const checkingStatusInterval = setInterval(checkCodeStatus, 1000);

    return () => {
      clearInterval(checkingStatusInterval);
    };
  }, [codeId, code, login]);

  const onFinishTimer = () => {
    setLoginFormState(LoginFormState.CodeExpired);
    setCodeId("");
  };

  const renderLoginForm = () => {
    switch (loginFormState) {
      case LoginFormState.FirstAttempt:
        return (
          <>
            <Typography variant='body1' component='ol'>
              <li>{t("login.form.step1")}</li>
              <li>{t("login.form.step2")}</li>
            </Typography>
            <PinCode onSubmit={login} errorMessage={loginError} />
          </>
        );
      case LoginFormState.WaitingVerification:
        return (
          <>
            <Typography variant='body1' component='ol'>
              <li>{t("login.form.waitingVerification")}</li>
            </Typography>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <DisplayShareCode code={code} />
              <CountDown duration={expirationTimeInSeconds} onFinish={onFinishTimer} />
            </Box>
          </>
        );
      case LoginFormState.CodeExpired:
        return (
          <>
            <Box my={2} display='flex'>
              <Typography variant='body2' sx={{ mr: 1 }}>
                <FontAwesomeIcon icon={faCircleExclamation} color='#FF3A3A' />
              </Typography>
              <Typography variant='body2'>
                {t("login.form.codeExpiredMessage", {
                  code,
                })}
              </Typography>
            </Box>
            <Typography variant='body1'>{t("login.form.codeExpiredInfo")}</Typography>
            <PinCode onSubmit={login} errorMessage={loginError} />
          </>
        );
    }
  };

  return (
    <Container
      component='main'
      sx={{ height: "100%", display: "flex", flex: 1, justifyContent: "center", marginBottom: 12 }}
    >
      <Grid
        container
        columnSpacing={{ lg: 2, sm: 1 }}
        rowSpacing={{ xs: 4 }}
        justifyContent='center'
        alignItems='center'
        alignSelf='center'
        sx={{ height: { sm: "100%" } }}
      >
        <Grid item lg={4} md={5} sm={6} xs={12}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems={{ sm: "flex-start", xs: "center" }}
          >
            <img src={logoImg} alt='Logo' width={244} />
            <Divider sx={{ width: 244, borderWidth: 2, borderColor: "#A0AAB1", my: 6 }} />
            <Typography variant='h2' fontSize={24} fontFamily='Poppins'>
              {t("login.title1")}
            </Typography>
            <Typography variant='h2' fontSize={24} fontFamily='Poppins'>
              {t("login.title2")}
            </Typography>
            <Typography variant='h2' fontSize={24} fontFamily='Poppins'>
              {t("login.title3")}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={5} md={6} sm={6} xs={12}>
          <Card sx={{ boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)" }}>
            <CardContent sx={{ p: 4 }}>
              {isPatient ? (
                <LoginPatient />
              ) : (
                <>
                  <Typography variant='h2'>{t("login.form.header")}</Typography>
                  <Box mt={1}>{renderLoginForm()}</Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
