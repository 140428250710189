import { Box, Typography } from "@mui/material";
import React from "react";

export interface DisplayShareCodeProps {
  code: string;
}

export const DisplayShareCode: React.FC<DisplayShareCodeProps> = ({ code }) => {
  return (
    <Box sx={{ mt: 4, mb: 3 }}>
      <Typography color='text.secondary' fontWeight={600} fontFamily='Poppins' fontSize={48}>
        {code}
      </Typography>
    </Box>
  );
};
