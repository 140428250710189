import React, { useContext } from "react";

import { NormReferenceLegend } from "../components/NormReferenceLegend";
import {
  Box,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ExaminationTypeHeader } from "../components/ExaminationTypeHeader";
import { StyledDocumentListItem } from "../components/StyledDocumentListItem";
import { format } from "date-fns/fp";
import { DocumentMeasurementItem } from "../components/DocumentMeasurementItem";
import { useLoaderData } from "react-router";
import { NormReference } from "../types/normReference";
import { DocumentStatus } from "../types/document";
import { getDocumentDetails } from "../api/document";
import { redirect } from "react-router-dom";
import { DocumentPreviewButton } from "../components/DocumentPreviewButton";
import {useTranslation} from "react-i18next";
import { DocumentsContext } from "../context";

export const documentLoader = async ({ params }: { params: any }) => {
  const { documentId } = params;
  const token = sessionStorage.getItem("token");

  if (!token) {
    return redirect("/");
  }

  return await getDocumentDetails({ documentId, token });
};

export type DocumentDetailsResponse = {
  documentId: string;
  samplingDate: Date;
  filePath: string;
  status: DocumentStatus;
  examinations: Array<{
    type: string;
    measurements: {
      measurementId: string;
      name: string;
      plNamePreview: string | null;
      value: string;
      unit: string | null;
      min: string | null;
      max: string | null;
      normReference: NormReference;
    }[];
  }>;
};


export const DocumentDetails: React.FC = () => {
  const { t } = useTranslation();
  const fetchedDetailsFromDeprecation = useLoaderData() as DocumentDetailsResponse;
  const documents = useContext(DocumentsContext);
  const {documentId} = useLoaderData() as {documentId: string};
  const data = documents.length ? documents.find(doc => doc.documentId === documentId ) : fetchedDetailsFromDeprecation as any

  return (
    <Container>
      <Box mt={4}>
        <Card>
          <Grid container px={2} py={1} alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Box display='flex'>
                <Typography variant='body2' color='text.secondary'>
                  {t('labDocumentDetails.dateOfMaterialCollection')}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 700, marginLeft: 3 }}>
                  {format("dd.MM.yyyy", new Date(data.samplingDate))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
        <NormReferenceLegend />
        <Box mt={7} />
        {(data as any).examinations.map((e: any) => (
          <Box key={e.type} my={4.5}>
            <ExaminationTypeHeader type={t('examinationType.'+e.type)} />
            <TableContainer>
              <Table sx={{ borderSpacing: "0 16px", borderCollapse: "separate" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Grid container columnSpacing={3}>
                        <Grid item xs={5} sm={4} md={4}>
                          {t('labDocumentDetails.test')}
                        </Grid>
                        <Grid item xs={3} sm={2} md={1}>
                          {t('labDocumentDetails.result')}
                        </Grid>
                        <Grid item xs={3} sm={2} md={2}>
                          {t('labDocumentDetails.unit')}
                        </Grid>
                        <Grid item xs={12} sm={4} md={5} display={{ xs: "none", sm: "block" }}>
                          {t('labDocumentDetails.referenceRange')}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {e.measurements.map((m: any) => (
                    <StyledDocumentListItem key={m.measurementId}>
                      <TableCell>
                        <DocumentMeasurementItem measurement={m} />
                      </TableCell>
                    </StyledDocumentListItem>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          boxShadow: "0px -4px 6px 0px rgba(0, 0, 0, 0.06)",
          padding: 2,
          position: "fixed",
          bottom: 50,
          left: 0,
          background: "#F1F1F1",
        }}
      >
        <Container>
          <DocumentPreviewButton
            documentId={data.documentId}
            size='medium'
            buttonText={t('labDocumentDetails.documentPreviewButton')}
            fullWidth
          />
        </Container>
      </Box>
    </Container>
  );
};
