import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { DocumentList } from "./DocumentList";
import { SearchDocumentList } from "./SearchDocumentList";
import { Box } from "@mui/material";
import { SearchTerms } from "./SearchTerms";
import { DocumentsContext } from "../context";

enum ViewType {
  search = "SEARCH",
  list = "LIST",
}


export const DocumentListWrapper: React.FC = () => {
  const [searchParams] = useSearchParams();
  const terms = searchParams.getAll("terms");
  const view: ViewType = terms.length > 0 ? ViewType.search : ViewType.list;
 

  const documents = useContext(DocumentsContext);
  return documents.length ? (
    <DocumentsContext.Provider value={documents}>
        <Box>
          <SearchTerms />
          {view === "SEARCH" ? (
            <SearchDocumentList documents={documents as any } />
          ) : (
            <DocumentList documents={documents as any} />
          )}
        </Box>
    </DocumentsContext.Provider>) : null
          
};