import { Box, Container } from "@mui/material";
import React from "react";

export const Footer: React.FC = () => {
  return (
    <Box
      component='footer'
      sx={{
        py: 3,
        px: 2,
        backgroundColor: "#0B2A32",
        height: 50,
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth={false}></Container>
    </Box>
  );
};
