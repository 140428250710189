import React, { useEffect, useState } from "react";
import { DocumentCategory } from "../types/document";
import {
  Checkbox,
  Drawer as MuiDrawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { faChevronLeft, faChevronRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentCategoryIcon } from "./DocumentCategoryIcon";
import { useTranslation } from "react-i18next";

export const drawerWidth = 212;
export const drawerWidthShrink = 68;

export const DocumentCategoryFilter: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const cat = searchParams.getAll("cat");
  const terms = searchParams.getAll("terms");
  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(!smallDevices);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, category: string) => {
    if (event.target.checked) {
      const updatedCatefories = [...cat, category];
      let params = createSearchParams({
        cat: updatedCatefories,
        terms,
      });
      setSearchParams(params);
    } else {
      handleDelete(category);
    }
    if (smallDevices) {
      handleDrawerClose();
    }
  };

  const handleDelete = (categoryToDelete: string) => {
    const updatedCatefories = cat.filter((c) => c !== categoryToDelete);
    let params = createSearchParams({
      cat: updatedCatefories,
      terms,
    });
    setSearchParams(params);
  };

  useEffect(() => {
    if (smallDevices) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [smallDevices]);

  return (
    <Box position='relative'>
      <IconButton
        sx={{
          backgroundColor: "#FFF",
          position: "fixed",
          width: 24,
          height: 24,
          border: "1px solid #A0AAB1",
          left: `calc(${open ? drawerWidth : drawerWidthShrink}px - 12px)`,
          top: { md: 208, xs: 272 },
          zIndex: (theme) => theme.zIndex.drawer + 1,
          "&:hover": {
            backgroundColor: "#F1F1F1",
          },
          display: { md: "none", xs: "inline-flex", xxs: "none" },
        }}
        onClick={open ? handleDrawerClose : handleDrawerOpen}
        size='small'
      >
        <FontAwesomeIcon icon={open ? faChevronLeft : faChevronRight} size='xs' color='#000' />
      </IconButton>
      <Drawer
        open={open}
        variant='permanent'
        anchor='left'
        sx={{
          display: { xs: "block", xxs: "none" },
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            paddingTop: { md: 26, xs: 34 },
            paddingLeft: { md: 3, xs: 2 },
            boxSizing: "border-box",
          },
        }}
      >
        <Stack direction='row' alignItems='center' spacing={1.2} sx={{ ml: "-5px", mb: 3 }}>
          <FontAwesomeIcon icon={faFilter} color='#526D75' />
          <Typography fontWeight='700' textTransform='uppercase' sx={{ display: { md: "inline", xxs: "none" } }}>
            {t('nav.filter.categories')}
          </Typography>
        </Stack>
        <Grid container spacing={1} mt={0.5}>
          <FormGroup>
            {Object.values(DocumentCategory).map((category) => (
              <Grid item key={category}>
                <Stack direction='row' alignItems='center' width='100%' justifyContent='space-between'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        onChange={(evt) => handleChange(evt, category)}
                        checked={cat.includes(category)}
                      />
                    }
                    label={
                      open ? (
                        <Typography fontSize={12}>{t(`documentCategory.${category.toLowerCase()}`)}</Typography>
                      ) : null
                    }
                    sx={{ mr: { md: 2, xs: 0 } }}
                  />

                  <DocumentCategoryIcon
                    category={category}
                    sx={{ border: "2px solid #FFF", borderRadius: 25, width: 22, height: 22 }}
                  />
                </Stack>
              </Grid>
            ))}
          </FormGroup>
        </Grid>
      </Drawer>
    </Box>
  );
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: open ? drawerWidth : drawerWidthShrink,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    width: open ? drawerWidth : drawerWidthShrink,
    backgroundColor: "#F1F1F1",
  },
}));
