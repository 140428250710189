import React from "react";
import { Box, Stack, Typography, Link } from "@mui/material";
import googlePlayBadgePL from "../assets/images/pl/google-play-badge.png";
import googlePlayBadgeEN from "../assets/images/en/google-play-badge.png";
import appStoreBadgePL from "../assets/images/pl/app-store-badge.svg";
import appStoreBadgeEN from "../assets/images/en/app-store-badge.svg";
import appScreenPL from "../assets/images/pl/app-screen.png";
import appScreenEN from "../assets/images/en/app-screen.png";
import { useTranslation } from "react-i18next";

export const LoginPatient: React.FC = () => {
  const { t, i18n } = useTranslation();
  const googlePlayBadge = i18n.language === "en" ? googlePlayBadgeEN : googlePlayBadgePL;
  const appStoreBadge = i18n.language === "en" ? appStoreBadgeEN : appStoreBadgePL;
  const appScreen = i18n.language === "en" ? appScreenEN : appScreenPL;
  return (
    <Box minHeight={278}>
      <Typography variant='h2'>{t("login.patient.header")}</Typography>
      <Box mt={1}>
        <Typography variant='body1' component='ol'>
          <li>{t("login.patient.step1")}</li>
          <li>{t("login.patient.step2")}</li>
        </Typography>
      </Box>
      <Stack direction='row' spacing={3} alignItems='flex-end'>
        <Box sx={{ position: "relative", minWidth: "141px", height: "190px" }}>
          <img
            style={{ position: "absolute", bottom: "-32px" }}
            src={appScreen}
            width={141}
            alt='Podgląd aplikacji Teczka Pacjenta'
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            overflowWrap: "anywhere",
          }}
        >
          <Link href='https://play.google.com/store/apps/details?id=pl.teczkapacjenta' target='_blank'>
            <img src={googlePlayBadge} width={155} alt='Pobierz z Google Play' />
          </Link>
          <Link href='https://apps.apple.com/pl/app/teczka-pacjenta/id6464666410' target='_blank'>
            <img src={appStoreBadge} width={155} alt='Pobierz z Google Play' />
          </Link>
        </Box>
      </Stack>
    </Box>
  );
};
