import { faFileImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { getDocumentPreviewUrl } from "../api/document";

export interface DocumentPreviewButtonProps {
  documentId: string;
  buttonText?: string;
}

export const DocumentPreviewButton: React.FC<DocumentPreviewButtonProps & ButtonProps> = ({
  documentId,
  buttonText,
  ...props
}) => {
  const previewFile = async () => {
    const token = sessionStorage.getItem("token");
    const url = await getDocumentPreviewUrl({ documentId, token: token || "" });
    window.open(url, "_blank");
  };
  return (
    <Button
      onClick={previewFile}
      color='secondary'
      variant='contained'
      sx={{
        borderRadius: 25,
        paddingX: 1.5,
      }}
      {...props}
    >
      {buttonText || "Podgląd"} <FontAwesomeIcon icon={faFileImage} style={{ width: 16, height: 16, marginLeft: 8 }} />
    </Button>
  );
};
