import { CSSObject } from "@emotion/react";
import { createTheme } from "@mui/material";

const globalStyles: CSSObject = {
  body: {
    background: "#F1F1F1",
    color: "#0B2A32",
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1F52FF",
      light: "#E0E7FF",
    },
    secondary: {
      main: "#0B2A32",
      dark: "#000000",
    },
    text: {
      primary: "#0B2A32",
      secondary: "#526D75",
    },
    error: {
      main: "#C90000",
    },
  },
  typography: {
    h2: {
      fontSize: 20,
      lineHeight: "30px",
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "180%",
    },
    body2: {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: "130%",
      textTransform: "uppercase",
    },
    caption: {
      fontSize: 12,
      lineHeight: "120%",
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 430,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globalStyles,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: "#F1F1F1",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: 20,
          lineHeight: "30px",
          fontWeight: 600,
        },
        textColorPrimary: {
          color: "#A0AAB1",

          "&.Mui-selected": {
            color: "#0B2A32",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#597FFF",
          height: 4,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          color: theme.palette.text.secondary,
          textTransform: "uppercase",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "130%",
          paddingTop: 0,
          paddingBottom: 0,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: 32,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Poppins",
          fontWeight: 500,
        },
        outlined: {
          background: "#ffffff",
          borderRadius: "25px",
          padding: "0px 12px",
          "&:hover": {
            background: "#E0E7FF",
          },
        },
        iconSizeSmall: {
          "& > *:nth-of-type(1)": {
            fontSize: "inherit",
          },
        },
        iconSizeMedium: {
          "& > *:nth-of-type(1)": {
            fontSize: "inherit",
          },
        },
        sizeSmall: {
          height: 32,
        },
        sizeMedium: {
          height: 40,
        },
        sizeLarge: {
          borderRadius: "30px",
          padding: "14px 24px",
          fontSize: 16,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: 4,
          textTransform: "uppercase",
        },
        filledSuccess: {
          color: "#3B6402",
          backgroundColor: "#D6F1AF",
        },
        filledWarning: {
          color: "#897F00",
          backgroundColor: "#F7F1A9",
        },
        message: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 2,
          background: "#526D75",
          color: "#ffffff",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          height: 34,
        },
        li: {
          "& a": {
            textDecoration: "none",
            color: "#0B2A32",
            paddingBottom: 5,
            display: "block",
          },
        },
        separator: {
          paddingBottom: 5,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          backgroundColor: "#ffffff",
        },
        notchedOutline: {
          borderColor: "#A0AAB1",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
            right: 16,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          padding: "4px 8px",
        },
        colorPrimary: {
          background: "#F1F1F1",
        },
        colorSecondary: {
          background: "#FFFFFF",
          color: "#0B2A32",
        },
        label: {
          padding: 0,
          paddingRight: 12,
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
        },
        deleteIcon: {
          color: "#A0AAB1",

          "&:hover": {
            color: "#A0AAB1",
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 6,
          borderRadius: 10,
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}
