import React from "react";
import { Theme } from "@emotion/react";
import { Button, ButtonProps, IconButton, SxProps } from "@mui/material";

export interface ResponsiveIconButtonProps {
  icon: React.ReactNode;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  sx?: SxProps<Theme>;
}

export const ResponsiveIconButton: React.FC<ResponsiveIconButtonProps> = ({
  icon,
  text,
  onClick,
  color = "primary",
  size,
  variant,
  sx,
}) => {
  return (
    <>
      <IconButton
        sx={{
          display: { xxs: "inline-flex", sm: "none" },
          width: 32,
          height: 32,
          borderRadius: 25,
          ...sx,
        }}
        size='small'
        color={color}
        aria-label={text}
        onClick={onClick}
      >
        {icon}
      </IconButton>
      <Button
        onClick={onClick}
        sx={{ display: { xxs: "none", sm: "inline-flex" }, ...sx }}
        endIcon={icon}
        color={color}
        variant={variant}
        size={size}
      >
        {text}
      </Button>
    </>
  );
};
