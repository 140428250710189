import React from "react";
import { Box, Container } from "@mui/material";
import { MainBar } from "../components";
import { Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { Footer } from "./Footer";

export const Layout: React.FC = () => {
  const location = useLocation();
  const excludePaths = ["/terms", "/privacy-policy", "/register-confirm", "/support"];
  const hideNav = excludePaths.includes(location.pathname);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <ScrollToTop />
      {!hideNav && <MainBar />}
      {hideNav ? (
        <Container
          component='main'
          sx={{ height: "100%", display: "flex", flex: 1, justifyContent: "center", marginBottom: 12 }}
        >
          <Outlet />
        </Container>
      ) : (
        <Outlet />
      )}
      {!hideNav && <Footer />}
    </Box>
  );
};
