import { TableRow, styled } from "@mui/material";

export const StyledDocumentListItem = styled(TableRow)({
  background: "#FFFFFF",
  borderRadius: 12,
  "&:hover": {
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
  },
  verticalAlign: "baseline",
  "& td:first-of-type": {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  "& td:last-of-type": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
});
