import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SearchTerms: React.FC = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const terms = searchParams.getAll("terms");
  const categories = searchParams.getAll("cat");

  const handleDelete = (termToDelete: string) => {
    let params = createSearchParams({
      terms: terms.filter((term) => term !== termToDelete),
      cat: categories.filter((cat) => cat !== termToDelete),
    });
    setSearchParams(params);
  };
  return terms.length > 0 || categories.length > 0 ? (
    <Box my={4}>
      <Stack direction='row' columnGap={1} rowGap={1} flexWrap='wrap'>
        <Typography>{t('documentList.searchedPhrase')}</Typography>
        {terms.map((term) => (
          <Chip
            key={term}
            color='secondary'
            variant='filled'
            label={term}
            onDelete={() => handleDelete(term)}
            deleteIcon={<FontAwesomeIcon icon={faXmark} color='#A0AAB1' width='16' height='16' />}
          />
        ))}
        {categories.map((cat) => (
          <Chip
            key={cat}
            color='secondary'
            variant='filled'
            label={t(`documentCategory.${cat.toLowerCase()}`)}
            onDelete={() => handleDelete(cat)}
            deleteIcon={<FontAwesomeIcon icon={faXmark} color='#A0AAB1' width='16' height='16' />}
          />
        ))}
      </Stack>
    </Box>
  ) : null;
};
