export const apiURL = process.env.REACT_APP_API_URL;

export const createDefaultHeaders = (idToken: string) =>
  new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`,
  });

export const createApiKeyHeaders = () =>
  new Headers({
    "Content-Type": "application/json",
    Authorization: `ApiKey ${process.env.REACT_APP_ADALO_API_KEY}`,
  });
