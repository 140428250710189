export type ParsedTokenData = {
  access: { firstName: string; lastName: string; patientId: string };
  exp: number;
  iat: number;
};

export const parseJwtToken = (token: string): ParsedTokenData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, base64Url] = token.split(".");
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export function buildUrlWithSearchParams({url, values, categories}:{url: string, values: string[], categories: string[]}): string {
  if (values.length === 0 && categories.length === 0) {
    return url;
  }

  const parsedUrl = new URL(url);

  if (values.length > 0) {
    for (const v of values) {
      parsedUrl.searchParams.append("values[]", v);
    }
  }

  if (categories.length > 0) {
    for (const c of categories) {
      parsedUrl.searchParams.append("categories[]", c);
    }
  }

  return parsedUrl.toString();
}
