import React, { useEffect, useReducer } from "react";
import { Box, Container } from "@mui/material";
import { LoaderFunctionArgs, Outlet, redirect, useLoaderData, useParams } from "react-router-dom";
import { DocumentCategoryFilter, drawerWidth, drawerWidthShrink } from "../components/DocumentCategoryFilter";
import { DocumentsContext } from "../context";
import { getDocumentList } from "../api/document";

export const documentListLoader = async ({ request }: LoaderFunctionArgs) => {
  const token = sessionStorage.getItem("token");
  const url = new URL(request.url);
  const searchTerms = url.searchParams.getAll("terms");
  const cat = url.searchParams.getAll("cat");

  if (!token) {
    return redirect("/");
  }

  return await getDocumentList({ token, query: searchTerms, cat });
};

export const ShareResults: React.FC = () => {
  const { documentId } = useParams();

  const showFilters = documentId === undefined;

  const [, dispatch] = useReducer(documentsReducer, []);
  const data = useLoaderData() as any;
  useEffect(() => {
    dispatch({ type: "SET_DOCUMENTS", payload: data.documents });
  }, [data.documents]);


  return (
    <DocumentsContext.Provider value={data?.documents}>
    <>
      {showFilters && <DocumentCategoryFilter />}
      <Container
        maxWidth={"xl"}
        component='main'
        sx={{
          height: "100%",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          marginBottom: 12,
          flexGrow: 1,
          marginLeft: showFilters ? { md: `${drawerWidth}px`, xs: `${drawerWidthShrink}px`, xxs: "auto" } : "auto",
          width: showFilters
            ? { md: `calc(100% - ${drawerWidth}px)`, xs: `calc(100% - ${drawerWidthShrink}px)`, xxs: "100%" }
            : "100%",
        }}
      >
        <Box mt={4} width='100%'>
          <Outlet />
        </Box>
      </Container>
    </>
    </DocumentsContext.Provider>
  );
};

function documentsReducer(documents: any, action: { type: any; payload: any; }) {
  switch (action.type) {
    case "SET_DOCUMENTS":
      return [documents, ...action.payload];
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
