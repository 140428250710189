import React from "react";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { MeasurementNorm } from "./MeasurementNorm";
import {useTranslation} from "react-i18next";

export interface DocumentMeasurementItemProps {
  measurement: {
    measurementId: string;
    name: string;
    plNamePreview: string | null;
    value: string;
    unit: string | null;
    min: string | null;
    max: string | null;
    normReference: string;
  };
}

export const DocumentMeasurementItem: React.FC<DocumentMeasurementItemProps> = ({ measurement }) => {
  const { t } = useTranslation();

  const isNormReferenceUnknown = measurement.normReference === "UNKNOWN" && (!measurement.min && !measurement.max);
  
  const buildReferenceText = (measurement: { min: any; max: any; name?: any; normReference: any; value?: any; unit?: any; }) => {
    const {min, max} = measurement;

    if(min && max) {
      return  `${measurement.min} - ${measurement.max}`
    } else if (min && !max) {
      return `>${measurement.min}`
    } else if (!min && max) {
      return `<${measurement.max}`
    }
  }
  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={5} sm={4} md={4}>
          <Typography>{measurement.name}</Typography>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <MeasurementNorm norm={measurement.normReference} display={{ xs: "inline", sm: "none" }} />
            <Typography sx={{ fontWeight: 700 }}>{measurement.value}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <Typography>{measurement.unit}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Stack direction='row' alignItems='center' spacing={1} maxWidth={{ xs: "unset", sm: "235px" }}>
            <MeasurementNorm norm={measurement.normReference} display={{ xs: "none", sm: "inline" }} />
            {isNormReferenceUnknown  ? (
              <Typography variant='body2'>{t('documentReadingError.referenceRangeFailed')}</Typography>
            ) : (
              <>
                <Typography color='text.secondary' variant='body2' display={{ xs: "inline", sm: "none" }}>
                  Zakres referencyjny:
                </Typography>
                <Typography
                  color='text.secondary'
                  fontSize={{ xs: 14, sm: 16 }}
                  fontWeight={{ xs: 700, sm: 400 }}
                >
                  {buildReferenceText(measurement)}
                  
                  </Typography>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
