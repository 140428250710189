import React from "react";
import lab from "../assets/images/categories/lab.svg";
import imaging from "../assets/images/categories/imaging.svg";
import prescription from "../assets/images/categories/prescription.svg";
import other from "../assets/images/categories/other.svg";
import { SxProps, Theme, styled } from "@mui/material";
import { DocumentCategory } from "../types/document";
import { useTranslation } from "react-i18next";

export interface DocumentCategoryIconProps {
  category: DocumentCategory;
  sx?: SxProps<Theme>;
}

export const DocumentCategoryIcon: React.FC<DocumentCategoryIconProps> = ({ category, sx }) => {
  const { t } = useTranslation();
  const StyledFilterIcon = styled("img")({});

  const renderIcon = () => {
    switch (category) {
      case DocumentCategory.Lab:
        return <StyledFilterIcon src={lab} alt={t(`documentCategory.${category.toLowerCase()}`)} sx={sx} />;
      case DocumentCategory.Imaging:
        return <StyledFilterIcon src={imaging} alt={t(`documentCategory.${category.toLowerCase()}`)} sx={sx} />;
      case DocumentCategory.Prescription:
        return <StyledFilterIcon src={prescription} alt={t(`documentCategory.${category.toLowerCase()}`)} sx={sx} />;
      case DocumentCategory.Other:
        return <StyledFilterIcon src={other} alt={t(`documentCategory.${category.toLowerCase()}`)} sx={sx} />;
      default:
        return <StyledFilterIcon src={other} alt={t(`documentCategory.other`)} sx={sx} />;
    }
  };
  return renderIcon();
};
