import { Box } from "@mui/material";
import React, { useEffect } from "react";

// generate fetch html from s3 url and save it to const variable to use it in dangerouslySetInnerHTML 
const fetchHtml = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const html = await response.text();
  return html;
}

export const TermsAndConditions: React.FC = () => {

  const [html, setHtml] = React.useState<string>('');

  useEffect(() => { 
    fetchHtml(`${process.env.REACT_APP_AWS_S3_PUBLIC_BUCKET_URL}/terms.html`).then((html) => {
      console.log(html)
      setHtml(html);
    });
  }, [html]);

  return (
    <Box display='flex' justifyContent='center' alignItems='center' style={{ width: "100%"}}>
        <style>
                {`
                .termsWrapper div {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                `}
              </style>
      <div className="termsWrapper" dangerouslySetInnerHTML={{ __html: html }} style={{display: "flex", width: "100%"}} />
    </Box>
  );
};
